.About ul {
  list-style-type: none;
  padding: 0;
}

.About li {
  display: inline-block;
  padding: 5px 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 30px;
  border: 3px solid #444;

  font-family: "PT Mono", monospace;
  font-size: 15px;
  font-weight: 600;
  color: #444;
  background-color: #eee;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.About li:hover {
  cursor: default;
}
