.Feed div {
  padding: 15px;
  margin-bottom: 15px;
  line-height: 25px;
  background-color: #eeeeee;
  border: 3px solid #444444;
  border-radius: 5px;
  box-shadow: 8px 8px 3px #bbbbbb;
  font-family: "PT Mono", monospace;
}

.Feed a {
  color: #2980b9;
  font-weight: 600;
}
