.Footer {
  margin-top: 50px;
  float: left;
  width: 100%;
}

.Footer a {
  color: #2980b9;
  font-size: 11px;
}
